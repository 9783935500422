import React from 'react';
import AuthorPosts from '../components/Blog/AuthorPosts';
import { graphql } from 'gatsby';

const BlogAuthorPage = (props) => {
  return <AuthorPosts propsData={props} />;
};

export default BlogAuthorPage;

export const authorPostsQuery = graphql`
  query authorPostsQuery(
    $skip: Int!
    $limit: Int!
    $authorId: Int!
    $siteId: Int!
    $userId: ID!
  ) {
    craftUser(remoteId: { eq: $userId }) {
      remoteId
      name
      photo {
        title
        ... on Craft_imagesEn_Asset {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 72)
            }
          }
        }
        ... on Craft_imagesRu_Asset {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 72)
            }
          }
        }
      }
    }

    allCraftBlogBlogEntry(
      limit: $limit
      skip: $skip
      sort: { fields: postDate, order: DESC }
      filter: { authorId: { eq: $authorId }, siteId: { eq: $siteId } }
    ) {
      nodes {
        id
        title
        slug
        author {
          name
          remoteId
        }
        postDate
        dateUpdated
        metaDescription
        featuredImage {
          id
          title
          url
          ... on Craft_imagesEn_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          ... on Craft_imagesRu_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
        featuredImageRetina {
          id
          title
          url
          ... on Craft_imagesEn_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          ... on Craft_imagesRu_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
        category {
          title
          id
          slug
        }
      }
    }
  }
`;
