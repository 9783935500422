import React from 'react';
import { Container, Row } from 'react-bootstrap';
import CategoryWidget from './CategoryWidget';

import './BlogIndex.css';
import IndexPostContent from './IndexPostContent';
import { useTranslation } from 'react-i18next';
import MetaTags from '../Seo/MetaTags';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';

const AuthorPosts = ({
  propsData: {
    data: { allCraftBlogBlogEntry, craftUser },
    pageContext,
  },
}) => {
  const { currentPage, numPages, categories } = pageContext;
  const { nodes } = allCraftBlogBlogEntry;
  const { t } = useTranslation('Blog');

  const title = t('author.seo.title', { name: craftUser.name });
  const description = t('author.seo.description', { name: craftUser.name });

  const pageTitle = t('author.title', { name: craftUser.name });
  const gatsbyImage = craftUser.photo && getImage(craftUser.photo.localFile);

  return (
    <>
      <MetaTags title={title} description={description} />

      <div className="news-section pt-12 pb-8 py-lg-23 bg-default-2">
        <Container>
          <Row className="justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-9 mt-9">
              <div className="section-title text-center mb-11 mb-lg-18">
                {gatsbyImage && (
                  <GatsbyImage
                    imgClassName="circle-lg mb-7 mx-auto"
                    alt={craftUser.name}
                    image={gatsbyImage}
                  />
                )}

                {!craftUser.photo && (
                  <StaticImage
                    src="../../assets/image/user.svg"
                    alt="Author"
                    className="circle-lg mb-7 mx-auto"
                    width={72}
                  />
                )}

                <h1 className="title gr-text-4">{pageTitle}</h1>
              </div>
            </div>
          </Row>

          <CategoryWidget categories={categories} disableSearch />

          <IndexPostContent
            nodes={nodes}
            currentPage={currentPage}
            numPages={numPages}
            type={'author/' + craftUser.remoteId}
          />
        </Container>
      </div>
    </>
  );
};

export default AuthorPosts;
